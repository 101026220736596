
import { ActivityType } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActivityTypeSpan extends Vue {
  @Prop({ required: true }) activity!: ActivityType;

  get activityText() {
    switch (this.activity) {
      case ActivityType.INDIVIDUAL_COACHING: {
        return "Individuele begeleiding";
      }
      case ActivityType.ASSESMENT: {
        return "Assesment";
      }
      case ActivityType.CLASSIC_TRAINING: {
        return "Begeleiding";
      }
      case ActivityType.WEBINAR: {
        return "Webinar";
      }
      case ActivityType.WORKSHOP: {
        return "Workshop";
      }
    }

    return 'Activiteitstype onbekend'
  }
}
